.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity ease .7s;
    picture, img {
        object-fit: cover;
        height: 100%;
    }
}

.btn {
    margin-top: 2.5rem;
    opacity: 0;
    transition: opacity ease .7s;
}

.bubbles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.bubble {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5.00rem;
    width: 14.88rem;
    &_1 {
        top: 28.88rem;
        left: 6.1rem;
    }
    &_2 {
        top: 26.5rem;
        right: 4.00rem;
    }
}

.logo {
    display: flex;
    width: 10.00rem;
    opacity: 0;
    transition: opacity ease .7s;
}

.text {
    margin-top: 3.3rem;
    max-width: 30.00rem;
    text-align: center;
    font-size: 1.13rem;
    font-weight: 600;
    line-height: 128%;
    color: var(--green);
    opacity: 0;
    transition: opacity ease .7s;
}

.title {
    margin-top: 5rem;
    position: relative;
    width: 39.75rem;
    height: 13.13rem;
    transform: translateY(2rem);
    opacity: 0;
    transition: opacity ease .7s, transform ease .7s;
    &__word {
        position: absolute;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8.00rem;
        line-height: 80%;
        color: var(--orange);
        img {
            position: absolute;
            top: -1.2rem;
            left: -2rem;
            z-index: -1;
            transform: rotate(-13deg);
            width: 6.13rem;
        }
        &_1 {
            top: 2.79rem;
            left: 0.38rem;
            font-size: 3.63rem;
        }
        &_2 {
            top: 0rem;
            left: 10.4rem;
        }
        &_3 {
            top: 2.9rem;
            right: 2.8rem;
            font-size: 3.63rem;
            color: var(--main);
            transform: rotate(13deg);
        }
        &_4 {
            bottom: 0rem;
            left: 1.9rem;
        }
    }
}

.wrap {
    padding: 1.5rem;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.active {
        .btn, .text, .bg, .logo {
            opacity: 1;
            transition-delay: 0.5s;
        }
        .title {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

@media only screen and (max-width: 1024px) {
    .bg {
        margin-top: -3.6rem;
        margin-left: 1.5rem;
        position: relative;
        width: 19.44rem;
        height: auto;
        picture, img {
            object-fit: unset;
            height: auto;
            width: 100%;
        }
    }
    .btn {
        margin-top: 0rem;
    }
    .bubble {
        &>*{
            font-size: 0.88rem !important;
        }
        &_1 {
            height: 3.86rem;
            width: 12.44rem;
            top: 10.48rem;
            left: -0.2rem;
        }
        &_2 {
            height: 3.25rem;
            width: 10.38rem;
            top: 14rem;
            right: 1.6rem;
        }
    }
    .logo {
        width: 6.50rem;
    }
    .text {
        margin-top: 2.6rem;
        max-width: 100%;
        font-size: 0.88rem;
    }
    .title {
        margin-top: -2.27rem;
        width: 20.56rem;
        height: 7.50rem;
       
        &__word {
            font-size: 4.25rem;
            img {
                top: -0.7rem;
                left: -1rem;
                width: 3.50rem;
            }
            &_1 {
                top: 1.59rem;
                left: 0;
                font-size: 2.00rem;
            }
            &_2 {
                top: 0.5rem;
                left: 5.4rem;
            }
            &_3 {
                top: 1.2rem;
                right: 1rem;
                font-size: 2.00rem;
            }
            &_4 {
                bottom: 0rem;
                left: 0.7rem;
            }
        }
    }
    .wrap {
        padding: 1.38rem;
        justify-content: space-between;
    }
}