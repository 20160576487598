.bg {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    bottom: 0;
    transform: translate(-50%);
    width: 100%;
    height: auto;
    z-index: -1;
}

.inner {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // transform: scale(0);
	transform-origin: 15% -20%;
    transition: transform ease 0.5s;
}

.text {
    padding: 0 1rem;
    width: 100%;
    text-align: center;
    color: var(--green);
    white-space: pre-wrap;
}

.wrap {
    --delay: 1s;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    
    font-size: 1rem;
    line-height: 126%;
    font-weight: 600;
    filter: drop-shadow(0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25));
    transform-origin: 15% -20%;
    // transform: rotate(20deg);
    transform: translateY(1rem);
    opacity: 0;
    transition: transform cubic-bezier(.19,1,.22,1) 1s, opacity cubic-bezier(.19,1,.22,1) 0.5s;
    z-index: 10;
    &.right {
        transform-origin: 85% -20%;
        // transform: rotate(-20deg);
        .inner {
			transform-origin: 85% -20%;
        }
    }
    &.top {
        .bg {
			bottom: unset;
            top: 0;
        }
    }
    &.desc {
        font-size: 1.25rem;
        line-height: 122%;
        font-weight: 500;
    }
    &.active {
        opacity: 1;
        // transform: rotate(0deg);
        transform: translateY(0rem);
        // transition: transform cubic-bezier(0.2, 2, 0.7, 1) 1s;
        transition-delay: var(--delay);
        .inner {
            transform: scale(1);
            transition-delay: var(--delay);
        }
    }
}

@media only screen and (max-width: 1024px) {
    .text {
        // font-size: 0.88rem;
        padding: 0 0.5rem;
    }
    .wrap {
        &.desc {
            font-size: 1rem;
        }
    }
}