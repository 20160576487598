
.percent{
    margin-top: 1rem;
    display: flex;
    align-items: center;
    font-size: 9.50rem;
    line-height: 7.63rem;
    text-transform: uppercase;
}
.text{
    font-size: 3.63rem;
    line-height: 3.63rem;
}
.wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-family: 'Playfair Display';
    color: var(--green);
}

@media only screen and (max-width: 1024px) {
    
.percent{
    margin-top: 1rem;
    display: flex;
    align-items: center;
    font-size: 5rem;
    line-height: 5rem;
    text-transform: uppercase;
}
.text{
    font-size: 2rem;
    line-height: 2rem;
}
}