.arrow {
    margin-top: 0.2rem;
    margin-right: 0.75rem;
    display: flex;
    width: 2rem;
}

.bg {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    width: 100%;
    z-index: -1;
    svg path {
        transition: fill ease 0.3s;
    }
}

.text {
    color: var(--main);
    font-size: 3.88rem;
    line-height: 120%;
    letter-spacing: 0.01em;
    font-family: 'TT Commons';
}

.wrap {
    padding-bottom: 0.43rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18.50rem;
    height: 5.5rem;
    cursor: pointer;
    z-index: 10;
    &_cover {
        .text {
            padding-right: 1.6rem;
        }
    }
    &_final {
        width: 28.88rem;
        height: 5.50rem;
        .text {
            padding-right: 0.3rem;
        }
    }
}

@media (hover: hover) {
    .wrap {
        &:hover {
            .bg svg path {
                fill: var(--orange);
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .arrow {
        margin-right: 0.65rem;
        display: flex;
        width: 1.31rem;
    }
    
    .text {
        font-size: 2.50rem;
        line-height: 2.5rem;
    }
    
    .wrap {
        padding-bottom: 0.5rem;
        width: 12.56rem;
        height: 4.50rem;
        &_cover {
            .text {
                padding-right: 0.9rem;
            }
        }
        
    &_final {
        width: 20.00rem;
        height: 4.5rem;
        .text {
            padding-right: 0.3rem;
        }
    }
    }
}