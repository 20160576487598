.result{

	position: absolute;
	top: 0.50rem;
	height: 1.56rem;
	width: 5.31rem;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--white);
	font-weight: 500;
	font-size: 1.13rem;
	line-height: 122%;
	letter-spacing: 0.03em;
	opacity: 0;
	transition: opacity ease 0.3s;
	span{

		border-bottom: 0.06rem solid var(--white);
	}
}
.text{
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	padding: 0 1.5rem;
	height: 6.56rem;
	width: 100%;
	font-weight: 500;
	font-size: 1.13rem;
	line-height: 122%;
	white-space: pre-wrap;
}
.wrap{
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 33.25rem;
	transition: filter ease 0.3s;
	cursor: pointer;
    svg path {
        transition: fill ease 0.3s, stroke ease 0.3s;
    }
	&.selected{
		color: var(--white);
		svg path {
			fill: var(--orange);
		}
		.result{
			opacity: 1;
		}
		&.right{
			svg path {
				fill: var(--green);
				stroke: var(--green);
			}
		}
	}
	&_0{
		.result{
			left: 1.38rem;
		}
	}
	&_1{
		.result{
			left: 10.31rem;
		}
	}
	&_2{
		.result{
			left: 18.19rem;
		}
	}
	&_3{
		.result{
			left: unset;
			right: 1.38rem;
		}
	}
	&_4{
		cursor: unset;
		.text{
			height: 9.75rem;
		}
		.result{
			top: 0.19rem;
			left: 10.13rem;
		}
	}
}
@media (hover: hover) {
    .wrap {
        &:hover:not(.wrap_4) {
			filter: drop-shadow(0rem 0.24rem 0.13rem rgba(0, 0, 0, 0.25));
        }
    }
}

@media only screen and (max-width: 1024px) {
	.result{
		top: 0.19rem;
		height: 1.13rem;
		width: 4.00rem;
		font-size: 0.75rem;
	}
	.text{
		padding: 0 0.9rem;
		height: 4.13rem;
		width: 100%;
		font-weight: 600;
		font-size: 0.88rem;
		line-height: 128%;
		white-space: unset;
	}
	.wrap{
		cursor: unset;
		width: 100%;
		transition: filter ease 0.3s;
		
		&_0{
			.result{
				left: 0.7rem;
			}
		}
		&_1{
			.result{
				left: 6.13rem;
			}
		}
		&_2{
			.result{
				left: 11.38rem;
			}
		}
		&_3{
			.result{
				left: unset;
				right: 0.6rem;
			}
		}
		&_4{
			cursor: unset;
			.text{
				height: 6.75rem;
			}
			.result{
				top: 0.19rem;
				left: 6.33rem;
			}
		}
	}
}