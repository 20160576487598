.bg{
	position: absolute;
	width: 100%;
	height: 100%;
    top: 0;
    left: 0;
	pointer-events: none;
	z-index: -1;
    picture, img{
        object-fit: cover;
        height: 100%;
    }
}
.btnWrap{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10;
}
.btn{
    position: absolute;
    display: flex;
    right: 3.50rem;
    bottom: 2.38rem;
    z-index: 100;
    transition: opacity ease .7s;
    opacity: 0;
}
.bubble{
    position: absolute;
    pointer-events: auto;
}
.bubbles{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;
}
.img {
    position: relative;
    height: 100%;
    overflow: hidden;
    user-select: none;
    transition: opacity ease 0.3s,  transform ease-in-out 0.7s;
    &.hide_full {
        opacity: 0;
        .img__back {
            opacity: 0;
        }
    }
    &.hide {
        .img__front {
            opacity: 0;
        }
    }
    &__front, &__back {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        background: var(--white);
        transition: opacity ease 0.3s;
    }
    &__back {
        img {
            opacity: 0;
        }
    }
    &__front {
        z-index: 10;
    }
    img {
        object-fit: cover;
        height: 100%;
    }
}
.level{
    position: absolute;
    top: 2rem;
    right: 3.50rem;
    font-size: 1.25rem;
    line-height: 122%;
    font-weight: 500;
    color: var(--green);
    z-index: 1000;
    opacity: 0;
    transition: opacity ease 0.3s;
}
.plate{
    display: none;
}
.screen{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
    mix-blend-mode: multiply;
}
.wrap{
	width: 100%;
	height: 100%;
	position: relative;
    overflow: hidden;
    z-index: 1;
    &.active{
        .level{
            opacity: 1;
        }
        .btn{
            opacity: 1;
            pointer-events: auto;
            transition-delay: 1.5s;
        }
    }
}
	
@media only screen and (max-width: 1024px) {
    .btnWrap{
        top: 50%;
        transform: translateY(-50%);
        max-height: 44rem;
        z-index: 9;
    }
    .btn{
        left: 50%;
        right: unset;
        bottom: 1.6rem;
        transform: translate(-50%);
    }
    .img {
     
    }
    .level{
        top: 1.38rem;
        right: 1.38rem;
        font-size: 0.88rem;
        line-height: 128%;
    }
    .plate{
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        height: 9.2rem;
        z-index: 9;
    }
    .screen{
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% - 4rem));
        width: 100%;
        height: 14.73rem;
    }
    .wrap{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}