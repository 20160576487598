.answers {
    margin-top: 1.63rem;
    display: flex;
    flex-direction: column;
}

.answer {
    display: flex;
    width: 100%;
    opacity: 0;
    transition: opacity ease 0.3s;
    &.answer {
        margin-top: 0.5rem;
    }
}

.btn {
    margin-left: auto;
    display: flex;
    z-index: 100;
}

.bubble {
    position: absolute;
}

.bubbles {
    position: absolute;
    left: 0;
    top: 0;
    width: 50rem;
    height: 100%;
}

.info {
    margin-left: 0.75rem;
    display: flex;
    flex-direction: column;
    padding-top: 6.79rem;
    padding-bottom: 2.5rem;
    width: 100%;
    max-width: 33.19rem;
    flex-shrink: unset;
}
.inner{
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
}
.img {
    position: relative;
    height: 100%;
    width: 49.25rem;
    overflow: hidden;
    user-select: none;
    transition: opacity ease 0.7s;
    &.hide {
        opacity: 0;
    }
    img {
        object-fit: cover;
        height: 100%;
    }
}

.level {
    position: absolute;
    top: 2rem;
    right: 3.50rem;
    font-size: 1.25rem;
    line-height: 122%;
    font-weight: 500;
    color: var(--green);
    z-index: 1000;
    opacity: 0;
    transition: opacity ease 0.3s;
}

.slides {
    position: relative;
    width: 100%;
    height: 100%;
    flex-shrink: unset;
    display: flex;
    &.toggle {
        .slide {
            &:nth-child(1) {
                opacity: 0;
                pointer-events: none;
            }
            &:nth-child(2) {
                opacity: 1;
                pointer-events: auto;
                transition-delay: 0.5s;
            }
        }
    }
}

.slide {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: opacity ease 0.5s;
    &:nth-child(2) {
        opacity: 0;
        pointer-events: none;
    }
}

.title {
    position: relative;
    display: flex;
    color: var(--orange);
    opacity: 0;
    transition: opacity ease .7s, transform ease .7s;
    font-size: 3.63rem;
    line-height: 5rem;
    font-weight: 500;
    letter-spacing: 0.01em;
    &__icon {
        position: relative;
        margin-left: 2.5rem;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--main);
        transform: rotate(13deg);
        line-height: 80%;
        img {
            position: absolute;
            top: 0.3rem;
            left: -1.7rem;
            z-index: -1;
            transform: rotate(-13deg);
            width: 4.83rem;
        }
    }
}

.result {
    margin: auto 0;
}

.wrap {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    overflow: hidden;
    transition: opacity ease .7s;
    &.active {
        .level{
            opacity: 1;
        }
        .title {
            opacity: 1;
        }
        .answer{
            opacity: 1;
            transition-delay: 0.5s;
            &:nth-child(2){
                transition-delay: 0.6s;
            }
            &:nth-child(3){
                transition-delay: 0.7s;
            }
            &:nth-child(4){
                transition-delay: 0.8s;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .answers {
        padding: 0 0.7rem;
        margin-top: 0;
        height: 100%;
        justify-content: flex-start;
    }
    .btn {
        margin-left: auto;
        margin-right: auto;
    }
    .bubbles {
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        max-height: 41.69rem;
        z-index: 10;
        pointer-events: none;
    }
    .inner{
        height: auto;
    }
    .info {
        margin-left: 0;
        display: flex;
        flex-direction: column;
        padding-top: 3.9rem;
        padding-bottom: 1.63rem;
        width: 100%;
        max-width: 100%;
        height: auto;
        overflow: hidden;
    }
    .img {
        margin-top: 0.8rem;
        position: relative;
        height: 20.6rem;
        width: 100%;
        flex-shrink: unset;
    }
    .level {
        top: 1.38rem;
        right: 1.38rem;
        font-size: 0.88rem;
        line-height: 128%;
    }
    .result {
        padding: 0 0.7rem;
    }
    .slides {
        margin-top: -2.1rem;
        height: auto;
        &.toggle {
            .slide {
                &:nth-child(1) {
                    opacity: 0;
                    pointer-events: none;
                }
                &:nth-child(2) {
                    opacity: 1;
                    pointer-events: auto;
                    transition-delay: 0.5s;
                }
            }
        }
    }
    .slide {
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        transition: opacity ease 0.5s;
        &:nth-child(2) {
            position: absolute;
            opacity: 0;
            pointer-events: none;
        }
    }
    .title {
        padding: 0 2.19rem;
        font-size: 2rem;
        line-height: 80%;
        letter-spacing: 0.03em;
        &__icon {
            margin-left: 2.3rem;
            img {
                top: -0.8rem;
                left: -1.4rem;
                width: 3.43rem;
            }
        }
    }
    .wrap {
        flex-direction: column;
        overflow: auto;
    }
}