.wrap{
	position: fixed;
	display: flex;
	align-items: center;
	right: 1rem;
	bottom: 1rem;
	z-index: 10000;
	gap: 0.5rem;
	input{
		width: 5rem;
	}
}
	
@media only screen and (max-width: 1024px) {
	.wrap{
		top: 1rem;
		left: 1rem;
		right: unset;
		bottom: unset;
	}
		
}