@font-face {
    font-family: 'TT Commons';
    src: url('TTCommons-Regular.woff2') format('woff2'),
        url('TTCommons-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Playfair Display SC';
    src: url('PlayfairDisplaySC-Regular.woff2') format('woff2'),
        url('PlayfairDisplaySC-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Manrope';
    src: url('Manrope-Regular.woff2') format('woff2'),
        url('Manrope-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Manrope';
    src: url('Manrope-SemiBold.woff2') format('woff2'),
        url('Manrope-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Manrope';
    src: url('Manrope-Medium.woff2') format('woff2'),
        url('Manrope-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
