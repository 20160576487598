.bg{
	position: absolute;
	width: 100%;
	height: 100%;
    top: 0;
    left: 0;
	pointer-events: none;
	z-index: -1;
    picture, img{
        object-fit: cover;
        height: 100%;
    }
}
.wrap{
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
}
	