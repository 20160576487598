
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
:root {
    --white: #FFFFFF;
    --black: #000000;
    --main: #F6F8EB;
    --grey: #C6C5C3;
    --grey-light: #F2F2F2;
    --green: #0F6857;
    --green-light: #408779;
    --orange: #FF6B3D;
}

html {
    font-size: 1.111vw;
    width: 100%;
    height: 100%;
    background: var(--main);
    -webkit-overflow-scrolling: auto;
}

body {
    position: relative;
    width: 100%;
    font-family: 'Manrope';
    transition: opacity ease 1s;
    scroll-behavior: smooth;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    flex-shrink: 0;
}

#root {
    position: relative;
    width: 100%;
    z-index: 10;
}

html, body, #root, .wrap {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.wrap{
    transition: background ease 0.3s;
}
h1, h2, h3{
    font-family: 'Playfair Display SC';
    font-weight: 400;
}
picture {
    display: flex;
    width: 100%;
}

img, svg {
    height: auto;
    width: 100%;
    flex-shrink: 0;
}

span, p, input, button, a, ul {
    font: inherit;
    color: inherit;
}

a {
    text-decoration: none;
}


@media (min-width: 768px) and (min-aspect-ratio: 16/10) {
    html {
        font-size: max(12px, 1.778vh);
    }
 }
// @media only screen and (max-width: 1024px) and (max-aspect-ratio: 16/10) {
//     html {
//         font-size: 2.11vw;
//     }
//     * {
//         cursor: none;
//     }
// }
@media screen and (max-width: 1023px) and (orientation: portrait){
    html {
        font-size: 4.267vw;
    }
    body {
        font-size: 0.88rem;
    }
    
}