.btn {
    margin-top: auto;
}

.inner {
    padding: 3.28rem 2rem 2.5rem 4.75rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.desc {
    margin-top: 3rem;
    color: var(--green);
    width: 38.88rem;
    font-size: 0.88rem;
}

.info {
    display: flex;
    flex-direction: column;
    width: 30.38rem;
    height: 100%;
}

.rating {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 46.38rem;
    opacity: 0;
    transition: opacity ease 0.5s;
    text-align: center;
    &.active {
        opacity: 1;
    }
}

.tiles {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.tile {
    padding: 0 1.81rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 3rem;
    color: var(--white);
    background: #6CA193;
    z-index: 10;
    &:nth-child(1), &:nth-child(2), &:nth-child(3) {
        height: 3.88rem;
        background: var(--orange);
        .tile__name {
            font-size: 1.50rem;
        }
        .tile__index, .tile__score {
            font-size: 1.56rem;
        }
    }
    &:nth-child(1) {
        .tile__bg {
            top: -0.38rem;
        }
    }
    &:last-child {
        .tile__bg {
            bottom: -0.38rem;
        }
    }
    &__bg {
        position: absolute;
        display: flex;
        left: 0;
        width: 35.88rem;
        z-index: -1;
    }
    &__name {
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.38rem;
        max-width: 31.25rem;
        font-weight: 600;
        hyphens: auto;
        flex-shrink: unset;
        white-space: nowrap;
    }
    &__index, &__score {
        width: 5.25rem;
        font-size: 1.13rem;
        display: flex;
        align-items: center;
        font-family: 'Playfair Display SC';
        font-weight: 500;
    }
    &__score {
        justify-content: flex-end;
    }
    &+.tile {
        margin-top: 0.25rem;
    }
}

.title {
    margin-top: -1rem;
    width: 23.75rem;
    color: var(--orange);
    font-size: 4.00rem;
    line-height: 88%;
}

.text {
    margin-top: 4.45rem;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 128%;
    color: var(--green);
    p+p {
        margin-top: 2rem;
    }
}

.wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 1024px) {
	.btn{
		margin-top: 1.5rem;
		width: 100%;

	}
.desc {
    margin-top: 1.5rem;
    width: 20.50rem;
	font-weight: 600;
    font-size: 0.88rem;
}
    .inner {
        padding: 3.13rem 1.19rem;
        flex-direction: column-reverse;
        justify-content: flex-end;
        height: auto;
    }
	.info {
		width: 100%;
		height: auto;
	}
	
	.rating {
		width: 100%;
	}
	
	.tiles {
		margin-top: 2rem;
		width: 100%;
	}
	
	.tile {
		padding: 0 0.5rem;
		width: 100%;
		height: 2.88rem;
		&:nth-child(1), &:nth-child(2), &:nth-child(3) {
			height: 3.38rem;
			background: var(--orange);
			.tile__name {
				font-size: 1rem;
			}
			.tile__index, .tile__score {
				font-size: 1rem;
			}
		}
		&:nth-child(1) {
			.tile__bg {
				top: -0.28rem;
			}
		}
		&:last-child {
			.tile__bg {
				bottom: -0.28rem;
			}
		}
		&__bg {
			width: 100%;
		}
		&__name {
			font-size: 0.88rem;
			max-width: 12.25rem;
		}
		&__index, &__score {
			width: 2.69rem;
			font-size: 0.88rem;
		}
	}
	.title {
		margin-top: 0;
		width: 100%;
		font-size: 3.38rem;
	}
	
	.text {
		margin-top: 1.38rem;
		font-size: 1rem;
		p+p {
			margin-top: 1.38rem;
		}
	}
	
    .wrap {
        overflow: auto;
    }
}