

.btn {
    position: absolute;
    display: flex;
    right: 3.50rem;
    bottom: 2.38rem;
    z-index: 100;
    opacity: 0.5;
    transition: opacity ease .7s;
    pointer-events: none;
    &.active{
        opacity: 1;
        pointer-events: auto;
    }
}


.logo {
    display: flex;
    width: 10.00rem;
}

.input{
    margin-top: 3rem;
    width: 49.75rem;
    height: 5.56rem;
    border-radius: 0.5rem;
    border: 0.13rem solid var(--green);
    color: var(--green);
    background: none;
    font-size: 2rem;
    text-align: center;
    outline: unset;
    &::placeholder{
        color: var(--green);
        opacity: 0.5;
    }
}

.title {
    margin-top: 11.06rem;
    position: relative;
    width: 71.50rem;
    color: var(--orange);
    text-align: center;
    font-size: 4.00rem;
    line-height: 85%;
   
}

.wrap {
    padding: 1.5rem;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.active {
        .btn, .text, .bg, .logo {
            opacity: 1;
            transition-delay: 0.5s;
        }
        .title {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

@media only screen and (max-width: 1024px) {
    
    .btn {
        position: relative;
        right: 0;
        bottom: 0;
        margin: auto auto 0;
    }
  
    .logo {
        width: 6.50rem;
    }
    .input{
        width: 100%;
        height: 3.75rem;
      
        font-size: 1.5rem;
    }
    .title {
        margin-top: auto;
        width: 20.56rem;
        height: auto;
        font-size: 2.13rem;
        line-height: 90%;
    }
    .wrap {
        padding: 1.38rem;
        justify-content: space-between;
    }
}