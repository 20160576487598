.bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    picture, img {
        object-fit: cover;
        height: 100%;
    }
}

.card {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    opacity: 0;
    pointer-events: none;
    transition: transform ease-in-out 0.5s;
    transform: translate3d(0, 0, 0);
    &.active {
        opacity: 1;
        pointer-events: auto;
        box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
        z-index: 50;
        will-change: transform;
        transform: translate3d(0, 0, 1px);
    }
    &.out {
        will-change: transform;
        z-index: 100;
        transform: translate3d(-5%, -110%, 2px) rotate(-4deg);
    }
    &.back {
        z-index: 1;
    }
}

.folder {
    position: relative;
    max-width: 87.00rem;
    max-height: 48.50rem;
    width: 100%;
    height: 100%;
    display: flex;
    &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
        div {
            position: absolute;
            width: 86.94rem;
            height: 48.50rem;
            box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
            &:nth-child(1) {
                left: -0.11rem;
                top: -1.16rem;
                background: var(--grey);
                transform: rotate(-0.61deg);
            }
            &:nth-child(2) {
                left: -0.25rem;
                top: 1.63rem;
                background: var(--grey-light);
                transform: rotate(-0.68deg);
            }
            &:nth-child(3) {
                left: 1.81rem;
                top: -0.94rem;
                width: 24.13rem;
                height: 48.50rem;
                background: var(--green);
                transform: rotate(-0.61deg);
            }
        }
    }
}

.wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) translateZ(0);
    transition: opacity ease 0.3s;
    &.hide {
        opacity: 0;
    }
}

@media only screen and (max-width: 1024px) {
    .card {
        &.active {
            box-shadow: none;
        }
    }
    .folder {
        max-width: unset;
        max-height: unset;
        &__bg {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 10;
            div {
                position: absolute;
                width: 100%;
                height: 100%;
                transform: none;
                box-shadow: none;
                &:nth-child(1) {
                    left: -0.11rem;
                    top: 0;
                    transform: none;
                }
                &:nth-child(2) {
                    transform: none;
                    left: 0rem;
                    top: 0rem;
                }
                &:nth-child(3) {
                    left: 1.44rem;
                    top: 0rem;
                    width: 10.75rem;
                    height: 22.06rem;
                    transform: none;
                }
            }
        }
    }
    .wrap {
        z-index: 10;
    }
}